<template>
  <v-row>
    <v-col cols="12" style="padding:0px !important;">
        <v-carousel
    class="hidden-sm-and-down"
    :show-arrows="false"
    hide-delimiters
    interval="12000"
    height="600"
  >
    <v-carousel-item
      v-for="(item, i) in slides"
      :key="i"
      :src="item.src"
      reverse-transition="fade-transition"
      transition="fade-transition"
    >
      <div class="overlay">
        <v-row class="fill-height" style="padding-top:100px;">
          <v-col cols="11" offset="1" style="color:#ffffff;">
            <v-layout wrap class="text-h2" style="color:#ffffff;">
              <b>{{ item.title }}</b>
            </v-layout>
            <v-layout>
              <v-col cols="8">
                <p class="mt-5" style="font-size: 18px;">
                  {{ item.text }}
                </p>
              </v-col>
            </v-layout>
            
            <!-- <v-layout wrap>
              <v-btn rounded color="#72bd61" dark>{{ item.btnText }}</v-btn>
            </v-layout> -->
            <v-layout wrap style="margin-top:100px;">
                      <v-col
                       v-for="category in categoryItems.slice(1, 10)"
                      :key="category.productCategoryId"
                        cols="1"
                        align="center"
                        style="border:1px solid #c4c4c4; background-color:transparent; border-radius:5px;padding:3px !important; margin:10px;"
                      >
                        <router-link :to="'/category/' + category.name +'/' + category.productCategoryId" style="color:#ffffff; text-decoration:none;">
                          <p class="mt-2">
                            <v-btn fab color="#72bd61"
                              ><v-icon size="30" color="#ffffff"
                                >mdi-pill</v-icon
                              ></v-btn
                            >
                          </p>
                          <p style="font-size:13px;">
                            {{ category.name }}
                          </p>
                        </router-link>
                      </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </div>
    </v-carousel-item>
  
  </v-carousel>
      <v-carousel
            class="hidden-md-and-up"
            cycle
            :show-arrows="false"
            hide-delimiters
            height="200px"
            interval="10000"
        >
            <v-carousel-item
            v-for="(item, i) in slides"
            :key="i"
            :src="item.src"
            transition="fade"
            reverse-transition="fade"
            >
            <div class="overlay">
                <v-row class="fill-height pt-5">
                <v-col cols="6" offset="1" style="color:#ffffff;">
                    <v-layout wrap class="text-h6" style="color:#ffffff;">
                    {{ item.title }}
                    </v-layout>
                    <p>
                    {{ item.text }}
                    </p>
                    <!-- <v-layout wrap>
                    <v-btn rounded color="#72bd61" dark small>{{
                        item.btnText
                    }}</v-btn>
                    </v-layout> -->
                </v-col>
                </v-row>
            </div>
            </v-carousel-item>
      </v-carousel>

      <v-container fluid>
        <v-layout wrap>
              <v-col cols="12">
                <h2>About Us</h2>
              </v-col>
              <v-col cols="12" md="6">
                  <p>
                    qikPharma is a One-Stop-Shop for pharma products and services in Africa.  
                    We have a passion and vision for Africa in the Pharma marketplace, 
                    to make supply and purchase of Pharma products a trusted and readily
                    accessible experience to both the rural and urban African dweller.
                  </p>

                  <h6>qikPharma is built on the following concept statements:</h6>
                  <p>
                    
                    <ul>
                      <li>
                        Making the supply and purchase of Pharma products a trusted and readily accessible experience to both rural and urban African population
                      </li>
                      <li>
                        Creating and managing sustainable relationships between pharma manufacturers, distributors, and retailers
                      </li>
                      <li>
                        Making the consumption of pharma products in Africa a safe, trusted and readily accessible experience
                      </li>
                      <li>
                        Promoting qikPharma as a One-Stop-Shop for achieving our vision for the African pharma marketplace.
                      </li>
                    </ul>
                  </p>
              </v-col>

              <v-col cols="12" md="5" offset-md="1">
                <v-img src="img/DMA_9758.jpg" alt="About Us image" width="450" style="border-radius:10px;" >
                </v-img>
              </v-col>
        </v-layout>

        <v-divider></v-divider>

        <v-layout row wrap class="mt-4 mb-5">
              <v-col cols="4" class="box2 ml-10">
                <h5 class="mt-7">Our Mission</h5>
                <p>
                  <ul>
                    <li>
                      To make the supply and purchase of Pharma products trusted and accessible to the wider African population
                    </li>
                    <li>
                      We intend to do by managing relationships between pharma manufacturers, distributors, and retailers.
                    </li>
                  </ul>
                </p>
              </v-col>

              <v-col cols="3" class="box1 ml-10">
                <h5 class="mt-7">Our Vision</h5>
                <ul>
                    <li>
                      To make the use of pharma products in Africa, safe, trusted and readily accessible.
                    </li>
                </ul>
              </v-col>

              <v-col cols="3" class="box2 ml-10">
                <h5 class="mt-7">Our Approach</h5>
                 <ul>
                    <li>
                      How shall we do it? Using our One-Stop-Shop concept.
                    </li>
                </ul>
              </v-col>
        </v-layout>

        <v-divider></v-divider>

        <v-layout class="mt-5">
              <v-col cols="12" md="5" class="mt-5">
                <v-img src="img/DMA_9699.jpg" alt="About Us image" width="400" height="300" style="border-radius:10px;" >
                </v-img>
              </v-col>

              <v-col cols="12" md="6" class="mt-5">
                <p class="mt-5">
                  qikPharma isn't just a platform, it's a revolution. We're dedicated to making essential pharmaceutical products conveniently accessible to everyone, everywhere. 
                  We believe healthcare shouldn't be a privilege, it's a right.
                </p>
                <p >
                  We welcome you to the qikPharma to explore the African pharma marketplace using our Web and Mobile apps. Welcome onboard! 
                  Leave your comments and feedback. And if you have any questions, please send those to us as well and we promise to respond within 24 hours.
                </p>
              </v-col>
        </v-layout>
      </v-container>

      <v-divider></v-divider>
  
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "About",


  data: () => ({
      slides: [
        {
          src: "https://res.cloudinary.com/dxw06ktju/image/upload/v1697799385/Slider-3_bczg17.png",
          title: "qikPharma: About us",
          text: "qikPharma isn't just a platform, it's a revolution. We're dedicated to making essential pharmaceutical products conveniently accessible to everyone, everywhere. We believe healthcare shouldn't be a privilege, it's a right.",
          btnText: "Shop Now",
          link: "#",
        },
        {
          src: "https://res.cloudinary.com/dxw06ktju/image/upload/v1697799387/Slider-2_crafkj.png",
          title: "Refill Prescriptions",
          text: "Check Prescription status, order a refill now.",
          btnText: "Refill Now",
          link: "#",
        },
      ],
       categoryItems: [],
  }),
  created() {
    this.getProductCat();
  },
  methods: {
    getProductCat() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
          this.categoryItems = response.data.data;
        });
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
}

.box1 {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff;
}

.box2 {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  color: #171a17;
}

.barcodeTitle {
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.barcodeText {
  color: #c2c2c2;
  font-size: 12px;
  margin-top: 15px;
  text-align: center;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}

p {
  line-height: 1.6;
}

li {
  line-height: 1.6;
}

</style>
